import logo from './logo.svg';
import './App.css';
import Signup from "./Signup";
import Thankyou from "./Thankyou";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
  <>
<BrowserRouter >
        {/* <GlobalStyle /> */}
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="thankyou" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>
  </>
  );
}

export default App;
