import React from "react";

const Navbar = () => {
    return (
        <>
        <div className="container py-4">
        <nav className="navbar navbar-expand-md bg-white main_nav">
                <div className="container-fluid main_container py-2">
                    <a className="navbar-brand" href="#">
                        {" "}
                        <img src="./images/logo.png" alt="logo" />
                        {/* <span className="logo_text">afli</span>{" "} */}
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav all_links">
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    item1
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    item2
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                Registration
                                </a>
                            </li>

                        </ul>
                     
                    </div>
                        <div className="right_btn">
                            <button className="signin">Sign in</button>
                        </div>
                    <div>
                    
                    </div>
                </div>
            </nav>
        </div>
        </>
    );
};

export default Navbar;
