import React from "react";
import Navbar from "./components/Navbar";

const Thankyou = () => {
    return (
        <>
            <section className="Thankyou_sec">
                <Navbar/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 col-12 mx-auto">
                            <div className="row">
                                <div className="col-lg-8 col-12">
                                    <div className="thankyou_left">
                                        <div className="thank_top">
                                             <div className="icons_text">
                                                 <div className="icon"><img src="./images/tick.png" alt="tick" /></div>
                                                 <div className="txt">Thank you Rizwan!</div>
                                             </div>
                                             <div className="codes">
                                             #4sds34521
                                             </div>
                                        </div>
                                        <div className="line_break">
                                            <img src="./images/line.png" alt="line_break" />
                                        </div>
                                        <div className="detail_box">
                                        <    div className="icons_text">
                                                 <div className="icon_fluent"><img src="./images/fluent.png" alt="tick" /></div>
                                                 <div className="txt">your Order is Confirmed</div>
                                             </div>
                                             <div className="details">
                                             we will send you the confirmation of your order shortly to the  e-email address you provide
                                             </div>
                                             <div className="checkbox_div">
                                            <input type="checkbox" name="check" />
                                            <div className="label_text">Email me with news and others</div>
                                             </div>
                                        </div>
                                        <div className="line_break">
                                            <img src="./images/line.png" alt="line_break" />
                                        </div>
                                        <div className="detail_box">
                                        <    div className="icons_text">
                                                 <div className="icon_fluent"><img src="./images/diamond.png" alt="tick" /></div>
                                                 <div className="txt">Subscription</div>
                                             </div>
                                             <div className="details">
                                             we will send you the confirmation of your order shortly to the  e-email address you provide
                                             </div>
                                             <div className="subscription">
                                             Manage your Subcription
                                             </div>
                                        </div>

                                        <div className="line_break">
                                            <img src="./images/line.png" alt="line_break" />
                                        </div>
                                        <div className="detail_box">
                                        <    div className="icons_text">
                                                 <div className="icon_fluent"><img src="./images/outline_delivery.png" alt="tick" /></div>
                                                 <div className="txt">Order Details</div>
                                             </div>

                                             <div className="contact_heading">
                                             Contact Details
                                             </div>
                                             <div className="details m-0">
                                             +343243455365464                                             
                                             </div>
                                             <div className="billing_heading">
                                             Billing Address
                                             </div>
                                             <div className="details m-0">
                                             Lorem Ipsum has been the industry's standard dummy text ever since the  1500s, when an unknown printer took a galley of type and scramble
                                             </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="thankyou_right">
                                        <div className="product_main">
                                            <div className="product_img">
                                                <img src="./images/product.png" alt="" />
                                            </div>
                                            <div className="product_detail">
                                                <div className="heading">
                                                Access To tiltok Shop Outreach Platform
                                                </div>
                                                <div className="center">
                                                    Free

                                                </div>
                                                <div className="product_des">
                                                Weekly Subscription
                                                </div>

                                            </div>

                                        </div>
                                        <div className="line_break_total">
                                            <img src="./images/line.png" alt="line_break" />
                                        </div>
                                        <div className="total_main">
                                        <div className="total">Total</div>
                                        <div className="total_value">USD $0.00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-12">
                                    <div className="continue_shoping">
                                        <button>
                                        Continue Shopping
                                        </button>
                                    </div>
                                    <div className="need_help">
                                    Need Help? <a href="#"> Contact us</a>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Thankyou;
