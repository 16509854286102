import React, { useState } from 'react';
import Navbar from "./components/Navbar";
// import { NavLink } from 'react-router-dom';

function Signup() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validate password on change
        if (name === 'password') {
            let newPasswordErrors = {};

            if (value.length < 8) {
                newPasswordErrors = { ...newPasswordErrors, length: 'Password must be 8 character long' };
            }
            if (!/[A-Z]/.test(value)) {
                newPasswordErrors = { ...newPasswordErrors, uppercase: ' & contain at least 1 uppercase,' };
            }
            if (!/[!@#$%^&*]/.test(value)) {
                newPasswordErrors = { ...newPasswordErrors, specialChar: '1 special character' };
            }
            if (!/\d/.test(value)) {
                newPasswordErrors = { ...newPasswordErrors, number: ' and 1 number' };
            }

            setErrors(prevErrors => ({
                ...prevErrors,
                password: newPasswordErrors,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form submitted:', formData);
        // Clear errors
        setErrors({});
    };

    return (
        <>
            <section className="signup_sec">
                <Navbar />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 mx-auto">
                            <div className="signup_main">
                                <div className='form_heading'>Welcome to Afli</div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 mt-4">
                                        <label htmlFor="email">Email:</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" value={formData.email} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="pwd">Password:</label>
                                        <input type="password" className="form-control" id="pwd" placeholder="Enter password" name="password" value={formData.password} onChange={handleChange} />
                                        {errors.password && (
                                            <div className="error">
                                                {errors.password.length && <span>{errors.password.length}</span>}
                                                {errors.password.uppercase && <span>{errors.password.uppercase}</span>}
                                                {errors.password.specialChar && <span>{errors.password.specialChar}</span>}
                                                {errors.password.number && <span>{errors.password.number}</span>}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="confirmPwd">Confirm Password:</label>
                                        <input type="password" className="form-control" id="confirmPwd" placeholder="Confirm password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                                    </div>
                                    <button type="submit" className="signup_btn mt-3">Submit</button>
                                    <div className='have_an_account'>
                                    Already have  an account? <a href='#'>Login</a> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Signup;
