import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import Thankyou from '.Thankyour';
// import Header from './components/Header';
// import About from './components/About';
// import Tokenomics from './components/Tokenomics';
// import Comunity from './components/Comunity';
// import Chart from './components/Chart';
// import Hold from './components/Hold';
// import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import reportWebVitals from './reportWebVitals';
import Thankyou from './Thankyou';
import Signup from './Signup';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

   {/* <Thankyou/>
   <Signup/> */}
   <App/>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
